.chat{
  position: fixed;
  right: -25px;
  bottom: 1%;
  background-color: transparent;
  color: rgb(100,234,255);
  border-radius: 20px;
  font-family: Outfit;
}
.chat-header-container {
  margin-left: 20px;
  display: flex;
  align-items: center;
  gap: 10px; 
}
.chat-viewport-container{
  transform: translateX(-40px);
}

.can-click{
  cursor: pointer;
}

.chat-pill {
  padding: 6px 16px;
  background: linear-gradient(349deg, var(--primary-color) 30%, var(--highlight-color) 85%);
  color: white;
  border-radius: 25px; 
  margin: 0;
}

.studio-title {
  color: white; 
  font-weight: lighter;
  font-size: 30px;
}

.chat-body {
  width: 87%;
  display: block;
  position: relative;
  padding: 1.3rem;
  max-width: 800px;
  border-radius: 25px;
}

.chat-bubble-image,
.user-online-image,
.user-online-image-scrollable{
  background: rgb(104,40,162);
  background: linear-gradient(349deg, var(--primary-color) 30%, var(--highlight-color) 85%);
}

.image-container {
  position: relative;
  right: 15px;
  width: 65px; 
  height: 65px; 
  border-radius: 50%;
}

.image-container::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  padding: 0.5px; 
  background: conic-gradient(var(--gradient-color, purple), transparent);
  -webkit-mask: 
    radial-gradient(farthest-side, transparent calc(100% - 4px), white calc(100% - 4px));
  mask: 
    radial-gradient(farthest-side, transparent calc(100% - 4px), white calc(100% - 4px));
  transform: rotate(180deg); 
  pointer-events: none; 
}
.image-container-empty::before{
  background: conic-gradient(var(--gradient-color, transparent), transparent);
}

.outer-image-container{
  margin-bottom: 0;
  margin-top: 0;
}

.image-inner-container {
  position: relative;
  top: 6px; 
  left: 6px; 
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  border-radius: 50%;
  overflow: hidden;
}

.chat-bubble-image {
  width: 100%; 
  height: auto; 
  object-fit: cover;
  object-position: center top;
}

.image-container-online{
  position: absolute;
  display: inline-block;
  width: 42px; 
  height: 42px; 
  margin: 2px;
  position: 'absolute';
  border-radius: 50%;
  top: 20px;
  color: 'white';
}

.users-online-inner-image-container{
  position: absolute;
  top: 3px;
  left: 2px; 
  width: calc(100% -6px);
  height: calc(100% - 6px);
  border-radius: 50%;
  overflow: hidden;
  border: 2px var(--gradient-color) solid;
}
.user-online-image{
  display: block;
  width: 100%; 
  height: auto; 
  object-fit: cover; 
  object-position: center top; 
  transform: scale(2.2) translateY(20%); 
}

.image-container-online-scrollable{
  display: inline-block;
  width: 110px; 
  height: 110px; 
  position: 'absolute';
  border-radius: 50%;
  color: 'white';

}

.users-online-inner-image-container-scrollable{
  height: calc(100% - 6px);
  border-radius: 50%;
  overflow: hidden;
  border: 2px var(--gradient-color) solid;
}
.user-online-image-scrollable{
  display: block;
  width: 100%; 
  height: auto; 
  object-fit: cover; 
  object-position: center top; 
  transform: scale(2.2) translateY(20%); 
}
.overflow-image-online{
  position: absolute;
  padding: 11px;
  background: linear-gradient(349deg, var(--primary-color) 30%, var(--highlight-color) 85%);
  color: white;
  top: 23px;
  height: 20px;
  width: 20px;
}
.chat-heading{
  color: white;
  text-align: center;
}
.chatText{
  color: rgb(100,234,255);
  text-align: center;
  font-family: Outfit;
}

.chat-container{
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  text-align: left;
  height: 445px;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  border: 0.5px rgb(53, 53, 53) solid;
  padding: 10px;
  transform: translate(-30px, -20px);
}
.Toastify__toast {
  background-color: rgba(0, 0, 0, 0.2) !important;
  color: white !important;
}

.chat-container::-webkit-scrollbar {
  width: 5px; 
}

.chat-container::-webkit-scrollbar-track {
  background-color: transparent; 
}

.chat-container::-webkit-scrollbar-thumb {
  background-color: var(--highlight-color);
  border-radius: 5px; 
}
.party-list::-webkit-scrollbar {
  width: 5px !important; 
}

.party-list::-webkit-scrollbar-track {
  background-color: transparent !important; 
}

.party-listr::-webkit-scrollbar-thumb {
  background-color: var(--highlight-color) !important;
  border-radius: 5px !important; 
}

.chat-bubble-container{
  position: relative;
  right: 20px;
}

.chat-bubble{
  position: relative;
  left: 80px;
  top: -75px;
  text-decoration: none;
  text-align: left;
  width: 75%;
  margin-right: auto;
  border-radius: 5px;
  color: white;
  word-wrap: break-word; 
  white-space: normal;
}
.chat-msg-text{
  background-color: rgba(3, 5, 29, 0.25);
}
.unityBtn,
.unityBtnToggled{
  padding: 30%;
  background-color: var(--highlight-color);
  color: var(--primary-color) ;
  border: var(--primary-color) 1px solid;
  border-radius: 50%;
  margin: 10px;
  display: flex; 
  justify-content: center; 
  align-items: center;
  cursor: pointer;
  position: absolute;
  bottom:1%;
  right: 2%;
  z-index: 250000;
}
.chatPos{
  right: 30px;
}
.chatPosToggled{
  right: 360px;
}
.bubble-timestamp{
  margin-right: auto;
  color: white;
}

.user-chat-bubble{
  background-color:#baffc9;
  width: 80%;
  color: black;
  text-decoration: none;
  margin-right: auto;
  border-radius: 5px;
  word-wrap: break-word; 
  white-space: normal;
}
.user-bubble-timestamp{
  color: white;
  position: relative;
}
.reaction-div {
  position: absolute;
  right: 10px;
  bottom: -20px;
  background: linear-gradient(349deg, var(--primary-color) 30%, var(--highlight-color) 85%);
  padding: 5px;
  z-index: 10;
  border-radius: 20px;
  cursor: pointer;
  font-size: smaller;
}

.reactions-container{
  position: absolute;
  width: 20%;
  right: -12%;
  height: 110%;
  bottom: -22px;
}

.reactions{
  position: absolute !important;
  left: 50% !important;
  transform: translateY(-3px) !important;
  cursor: pointer!important;
  bottom: 30px !important;
}

.reactions-list{
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 5px;
  padding: 5px;
  font-size: 25x;
}


.reactions-list-ul{
  padding-inline-start: 5px;
}
.chat-input{
  display: flex;
  align-items: center;
  margin: 1% auto;
  margin-top: 4%;
  width: 80%;
  z-index: 25000 !important;
  padding:0.2rem 0.2rem 0.2rem 1.2rem;
  border: 2px solid transparent;
  border-radius: 50px;
  background-color: rgba(0,19,53,0.5);
  position: relative;
  z-index: 2;
}

.chat-input::before{
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 25px;
  border: 2px solid transparent;
  background: linear-gradient(65deg, var(--primary-color), var(--secondary-color), var(--highlight-color), var(--error-color) 40%, var(--accent-color), var(--primary-color) 60%) border-box;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  pointer-events: none;
  z-index: 1;
}

#chat-input{
  border: none;
  background-color: transparent;
  width: 90%;
  height: 100%;
  color: white;
  font-size: larger;
}

#chat-input:focus {
  outline: none; 
}

.emoji-button {
  margin-left: 5px; 
  font-size: 240%;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  color: lightslategray;
}

.emoji-picker{
  position: absolute;
  bottom: 130%;
  right: 10%;
  z-index: 3500000;
}
.emoji-picker-reaction{
  position: fixed;
  bottom: 10%;
  z-index: 3500000;
}


.send-button{
  background: linear-gradient(79deg, var(--primary-color) 0%, var(--secondary-color) 55%, var(--accent-color) 63%, var(--error-color) 100%);
  border: none;
  color: black;
  border-radius: 50%;
  padding: 6px;
}

.is-typing-container{
  position: absolute;
  font-size: 12px;
  color: lightgray;
  bottom: 86px;
  left: 40px;
}

.dot-text-chat{
  position: absolute;
  left: 45px;
  top: 30px;
  font-size: 200%;
  margin: 0;
  padding: 0;;
}
.red-text-chat{
  color: red;
}
.green-text-chat{
  color: lime;
}


/* Wobble Animation */
@keyframes wobble {
  0%, 100% {
    transform: translateX(0);
  }
  15%, 45%, 75% {
    transform: translateX(-1px);
  }
  30%, 60%, 90% {
    transform: translateX(1px);
  }
}

.wobble {
  animation: wobble 0.5s ease-in-out;
}

.scroll-to-bottom {
  position: fixed; 
  bottom: 100px;
  right: 30px;
  background-color: transparent;
  border-radius: 25px;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bold;
  color: white;
  border: 2px solid transparent;
  cursor: pointer;
  z-index: 1000;
}
.scroll-to-bottom:hover {
  background-color: rgba(3, 5, 29, 0.25);
  color: white;
}

.ellipsis{
  background-color: rgba(3, 5, 29, 0.25);
  border-radius: 50%;
  padding: 4px;
  position: relative;
  bottom: 1px;
  left: 2px;
}

.card{
  background-color: rgba(38, 38, 63, 0.884);
  border-radius: 15px;
  margin: 0 auto ;
  width: 50%;
  color: white;
  max-height: 80%;
}

.party-input {
  background-color: rgba(0, 0, 0, 0.1); 
  border: 1px solid grey; 
  color: white;
  padding: 8px 12px; 
  border-radius: 4px; 
  outline: none; 
}

.party-input:focus {
  border-color: lightgrey; 
}

.party-list {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 80%;
}

.party-list li {
  color: white;
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  margin-bottom: 5px;
  cursor: pointer;
  border-radius: 4px;
}

.party-list li:hover {
  background-color: rgba(0, 0, 0, 0.26);
}

.party-list li.selected {
  background-color: rgba(255, 255, 255, 0.2);
}

.party-icon{
  background-image: url("/public/icons/party-button-icon.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  transform: translate(-5px, 8px);
  cursor: pointer;
}

.go-public{
  background-image: url("/public/icons/party-host-go-public-icon.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 60px;
  height: 60px;
  cursor: pointer;
}
.go-private{
  background-image: url("/public/icons/party-host-go-private-icon.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 60px;
  height: 60px;
  cursor: pointer;
}
.start-voice{
  background-image: url("/public/icons/party-host-start-voice-icon.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 60px;
  height: 60px;
  cursor: pointer;
}
.end-voice{
  background-image: url("/public/icons/party-host-end-voice-icon.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 60px;
  height: 60px;
  cursor: pointer;
}
.end-party{
  background-image: url("/public/icons/party-host-end-party-icon.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 60px;
  height: 60px;
  cursor: pointer;
}

.person-logo{
  background-image: url("/public/icons/party-host-icon.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  margin-left: 15px;
}

.mute-myself{
  background-image: url("/public/icons/party-mode-mute-myself.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 60px;
  height: 60px;
  cursor: pointer;
}
.unmute-myself{
  background-image: url("/public/icons/party-mode-unmute-myself.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 60px;
  height: 60px;
  cursor: pointer;
}
.unsolo-myself{
  background-image: url("/public/icons/party-mode-unsolo-myself.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 60px;
  height: 60px;
  cursor: pointer;
}

.leave-party{
  background-image: url("/public/icons/party-mode-leave-party.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 60px;
  height: 60px;
  cursor: pointer;
}

.kick-mute-solo-controls{
  background-color: rgba(37, 37, 37, 0.445);
  border-radius: 30px;
  position: absolute;
  bottom: 0;
  height: 250px;
  padding: 10px;
  right: 70px;
}
.kick{
  background-image: url("/public/icons/party-people-kick.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.mute{
  background-image: url("/public/icons/party-people-mute.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.unmute{
  background-image: url("/public/icons/party-people-unmute.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.solo{
  background-image: url("/public/icons/party-people-solo.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.unsolo{
  background-image: url("/public/icons/party-people-unsolo.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.spin{
  background-image: url("/public/icons/activity-icon-1.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 90px;
  height: 90px;
}
.poke{
  background-image: url("/public/icons/activity-icon-2.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 90px;
  height: 90px;
}

.stand{
  background-image: url("/public/icons/activity-icon-3.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 90px;
  height: 90px;
}
.join-logo{
  background-image: url("/public/icons/join-party-icon.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  margin-left: 15px;
}
.audio-status-online{
  position: absolute;
  width: 20px!important;
  height: 20px !important;
  z-index: 6;
  transform: translateX(25px);
}

.muted{
  background-image: url("/public/icons/party-people-mute.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.solod{
  background-image: url("/public/icons/party-people-solo.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.host-indicator{
  position: absolute;
  width: 20px!important;
  height: 20px !important;
  z-index: 6;
  background-image: url("/public/icons/party-host-icon.png");
  background-size: contain;
  background-repeat: no-repeat;
  transform: translate(-25px, 35px);
}

.chat-container-filtered{
  width: 100%;
  overflow: hidden;
  text-align: left;
  height: 445px;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  border: 0.5px rgb(53, 53, 53) solid;
  padding: 10px;
  transform: translate(-30px, -20px);
}
.chat-container-filtered::-webkit-scrollbar {
  width: 5px; 
}

.chat-container-filtered::-webkit-scrollbar-track {
  background-color: transparent; 
}

.chat-container-filtered::-webkit-scrollbar-thumb {
  background-color: var(--highlight-color);
  border-radius: 5px; 
}

.filtered-chat-container{
  height: 340px;
  min-height: 340px;
  overflow-y: scroll;
}

.filtered-container{
  height: 340px;
  display:flex;
  flex-direction: row;
}



.filtered-history-bubble, 
.current-user, 
.other-user {
  position: relative;
  width: 150px;
  padding: 5px;
  margin: 10px;
  color: white;
  background: #68686863;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  box-shadow: 0 0 0 0.5px #ff6b00;
  word-wrap: break-word;
  overflow-wrap: break-word; 
  white-space: normal; 
}

.other-user { 
  box-shadow: 0 0 0 0.5px #ff0000;
}

.current-user { 
  box-shadow: 0 0 0 0.5px #00ff37;
}

.avatar-filtered{
  width: 100%;
  height: 340px;
  background-size: cover;
  background-repeat: no-repeat;
  position: fixed;
  left: 100px;
  pointer-events: none;
}

.back-actvity,
.start-activity{
  cursor: pointer;
}
.voice-mini-icon{
  background-image: url("/public/icons/voice-mini-icon.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}
.party-status{
  position: absolute;
  top: -30px;
  right: 25px;
  z-index: 550000000;
  color: white;
  font-size: smaller;
  line-height: 0px;
}
.public-party{
  background-image: url("/public/icons/public-mini-icon.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}
.private-party{
  background-image: url("/public/icons/lock-icon.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}
.voice-chat{
  background-image: url("/public/icons/voice-mini-icon.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}
.selected-user{
  border:#ff0000 1px solid;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.205);
  transform: translate(14px, 0px);
  width: 80px;
}

.back-button{
  cursor: pointer;
  position: absolute;
  bottom: 45px;
  right: 200px;
  z-index: 250000000;
  background: none;
  border: none;
  padding: 0;
  font: inherit; 
  color: white;
}